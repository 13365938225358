import "../../styles/contacto.scss";
// @ts-ignore
import contacto from "../../assets/contacto.png";

import { useState } from "react";
import { useTranslation } from "react-i18next";

type Field = "empresa" | "nombre" | "telefono" | "email";

type FormData = {
  empresa: string;
  nombre: string;
  telefono: string;
  email: string;
};

export const Contacto = () => {
  const [t, i18n] = useTranslation("global");
  const [formData, setFormData] = useState<FormData>({
    empresa: "",
    nombre: "",
    telefono: "",
    email: "",
  });
  const [touched, setTouched] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [message, setMessage] = useState("");

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getInputClassName = (fieldName: Field) => {
    if (!touched) return "";
    return formData[fieldName] && isFieldValid(fieldName) ? "valid" : "invalid";
  };

  const isFieldValid = (fieldName: Field) => {
    switch (fieldName) {
      case "email":
        return isValidEmail(formData.email);
      default:
        return formData[fieldName] !== "";
    }
  };

  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const isFormValid = () => {
    // Aquí puedes agregar más validaciones según necesites
    return formData.empresa && formData.nombre && isValidEmail(formData.email);
  };

  const sendEmail = async (e: any) => {
    e.preventDefault();

    setTouched(true);

    if (!isFormValid()) {
      console.log("Datos del formulario inválidos");
      return;
    }

    setIsSending(true);
    setMessage("");

    try {
      await fetch("https://msa-emails.vercel.app/api/send-email", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: { "Content-Type": "application/json" },
      });
      setMessage("Email enviado con éxito!");
      setFormData({
        empresa: "",
        nombre: "",
        telefono: "",
        email: "",
      });
      setTouched(false);
    } catch (error) {
      setMessage("Error al enviar el email.");
    } finally {
      setIsSending(false);
      setTimeout(() => {
        setMessage("");
      }, 5000);
    }
  };

  return (
    <div className="contacto-container">
      <div id="contacto"></div>
      <div className="grid-container-contacto">
        <div className="image-container">
          <img src={contacto} alt="Descripción de la imagen" className="contacto-img" />
        </div>
        <div className="content-container">
          {/* <h2>Solicita una reunión</h2> */}
          <p>
            <b>{t('contacto.title')}</b>
            <br /> {t('contacto.title2')}
          </p>
          <form>
            <div className="form-row">
              <input
                type="text"
                id="empresa"
                name="empresa"
                placeholder={t('contacto.input1')}
                value={formData.empresa}
                onChange={handleInputChange}
                className={getInputClassName("empresa")}
              />
            </div>
            <div className="form-row">
              <input
                type="text"
                id="nombre"
                name="nombre"
                placeholder={t('contacto.input2')}
                value={formData.nombre}
                onChange={handleInputChange}
                className={getInputClassName("nombre")}
              />
            </div>
            <div className="form-row">
              <div className="half-width">
                <input
                  type="text"
                  id="telefono"
                  name="telefono"
                  placeholder={t('contacto.input3')}
                  value={formData.telefono}
                  onChange={handleInputChange}
                  className={getInputClassName("telefono")}
                />
              </div>
              <div className="half-width half-width-f flex">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder={t('contacto.input4')}
                  value={formData.email}
                  onChange={handleInputChange}
                  className={getInputClassName("email")}
                />
              </div>
            </div>
            <div className="btn-container">
              <div className="message">{message}</div>
              <button type="submit" onClick={sendEmail}>
                {isSending ? t('contacto.input6') : t('contacto.input5')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
