import { VideoCompany } from "./VideoCompany";

import "../../styles/tecnologiaElectoral.scss";
import CarouselCard from "../Carrousel";
// @ts-ignore
import comitia from "../../assets/comitia.svg";
// @ts-ignore
import bue from "../../assets/bue.svg";
// @ts-ignore
import logoSuite from "../../assets/logo-suite.svg";
// @ts-ignore
import turing from "../../assets/icon-turing.svg";
// @ts-ignore
import note from "../../assets/icon-note.svg";
// @ts-ignore
import soe from "../../assets/icon-soe.svg";

// @ts-ignore
import adreani from "../../assets/andreani.jpg";
// @ts-ignore
import aws from "../../assets/aws.png";
// @ts-ignore
import intel from "../../assets/intel.png";
// @ts-ignore
import st from "../../assets/st.png";
// @ts-ignore
import iram from "../../assets/iram.jpg";
// @ts-ignore
import iso from "../../assets/iso.png";
import { useTranslation } from "react-i18next";

export const TecnologiaElectoral = () => {
  const [t, i18n] = useTranslation("global");
  const archivoPDF = process.env.PUBLIC_URL + "Antecedentes-Resumidos.pdf";
  const cardData = [
    {
      icon: bue,
      title: "Bue",
    },
    {
      icon: turing,
      title: "Turing",
    },
    {
      icon: soe,
      title: t("servicios.tecnologia.card1"),
    },
    {
      icon: note,
      title: "Notebox",
    },
  ];

  return (
    <div className="tab-container">
      <div className="tab-text">
        <img src={comitia} alt="" />
        <h3>{t("servicios.tecnologia.text1")}</h3>
        <p>{t("servicios.tecnologia.text2")}</p>
      </div>
      <div className="datos-container">
        <div className="box-data">
          <span>+200</span>
          <p>{t("servicios.tecnologia.number1")}</p>
        </div>
        <div className="box-data">
          <span>+12K</span>
          <p>{t("servicios.tecnologia.number2")}</p>
        </div>
        <div className="box-data">
          <span>+50</span>
          <p>{t("servicios.tecnologia.number3")}</p>
        </div>
      </div>

      <div className="video-company">
        <VideoCompany />
      </div>

      <div className="suite">
        <img src={logoSuite} alt="Logo" className="logo-suite" />
        <p
          dangerouslySetInnerHTML={
            { __html: t("servicios.tecnologia.suite") } as any
          }
        />
      </div>
      <CarouselCard cardConfig={cardData} />
      <div className="box-link-blue">
        <a
          href="http://suiteelectoral.com.ar/"
          target="_blank"
          className="link-blue"
        >
          {t("servicios.tecnologia.suiteBtn")}
        </a>
      </div>
      <div className="box-link-download">
        <a href={archivoPDF} download target="_blank" className="link-down">
          <p>{t("servicios.tecnologia.descargable")}</p>
        </a>
      </div>

      <div className="component">
        <div className="left-column">
          <h2>PARTNERSHIPS</h2>
          <div className="logos">
            <img src={adreani} alt="Logo 1" />
            <img src={aws} alt="Logo 1" />
            <img src={intel} alt="Logo 1" />
            <img src={st} alt="Logo 1" />
          </div>
          <p>{t("servicios.tecnologia.partnership")}</p>
        </div>
        <div className="right-column">
          <h2
            dangerouslySetInnerHTML={
              { __html: t("servicios.tecnologia.calidad") } as any
            }
          />
          <div className="text-logos">
            <p>{t("servicios.tecnologia.calidadText")}</p>
            <div className="logos">
              <img src={iram} alt="Logo 1" />
              <img src={iso} alt="Logo 1" />
            </div>
          </div>
          <p
            className="sub-text"
            dangerouslySetInnerHTML={
              { __html: t("servicios.tecnologia.politic") } as any
            }
          />
        </div>
      </div>
    </div>
  );
};
