import { Routes, Route } from 'react-router-dom';
import { Home } from '../pages/Home';
import { SomosMsa } from '../pages/SomosMsa';
import { Noticias } from '../pages/Noticias';
import { Nota } from '../pages/Nota';
import { QuienesSomos } from '../pages/QuienesSomos';
import { Fundacion } from '../pages/Fundacion';

export const Main = () => {
  return (
    <Routes>
      <Route index path='/' element={<Home/>}></Route>
      <Route  path='/somosMSA' element={<SomosMsa/>}></Route>
      <Route  path='/quienes-somos' element={<QuienesSomos/>}></Route>
      <Route  path='/fundacion' element={<Fundacion/>}></Route>
      <Route  path='/noticias' element={<Noticias/>}></Route>
      <Route path="/nota/:idNota" element={<Nota/>}></Route>
    </Routes>
  )
}