import { useTranslation } from "react-i18next";
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header";
import "../styles/quienes-somos.scss";

export const QuienesSomos = () => {
  const [t, i18n] = useTranslation("global");

  const itemsData = [
    { id: 1, imageUrl: 'url_de_la_imagen_1', title: 'Sergio Angelini', subtitle: t("us-grid.item1") },
    { id: 2, imageUrl: 'url_de_la_imagen_2', title: 'Nicolás Angelini', subtitle: t("us-grid.item2") },
    { id: 1, imageUrl: 'url_de_la_imagen_1', title: 'Nicolás Deane', subtitle: t("us-grid.item3") },
    { id: 2, imageUrl: 'url_de_la_imagen_2', title: 'Jorge García', subtitle: t("us-grid.item4") },
    { id: 1, imageUrl: 'url_de_la_imagen_1', title: 'Tomás Lanzillota', subtitle: t("us-grid.item5") },
    { id: 2, imageUrl: 'url_de_la_imagen_2', title: 'Juan Pablo Prezzoli', subtitle: t("us-grid.item6") },
    { id: 1, imageUrl: 'url_de_la_imagen_1', title: 'Sebastián Angelini', subtitle: t("us-grid.item7") },
    { id: 2, imageUrl: 'url_de_la_imagen_2', title: 'Lorena Montespier', subtitle: t("us-grid.item8") },
    { id: 1, imageUrl: 'url_de_la_imagen_1', title: 'Alejandro Buján', subtitle: t("us-grid.item9")},
    { id: 2, imageUrl: 'url_de_la_imagen_2', title: 'Ezequiel Valerio', subtitle: t("us-grid.item10") },
    { id: 1, imageUrl: 'url_de_la_imagen_1', title: 'Alvaro Osorio', subtitle: t("us-grid.item11") },
    { id: 2, imageUrl: 'url_de_la_imagen_2', title: 'Maximiliano Lulic', subtitle: t("us-grid.item12")},
  ];

  return (
    <>
     <Header />
     <div className="quienes-somos-container">
        <div className="flex-container column content">
          <h2>MANAGMENT TEAM</h2>
          <p>{t('msa.parrafo')}</p>
          <div className="grid-container">
            {itemsData.map((item) => (
              <div key={item.id} className="grid-item">
                {/* <img src={item.imageUrl} alt={`Imagen ${item.id}`} /> */}
                <h3>{item.title}</h3>
                <p>{item.subtitle}</p>
              </div>
             ))}
        </div>
        </div>
     </div>
     <Footer />
    </>
  )
}
