// @ts-ignore
import singularIT from '../../assets/singularStories.svg';
// @ts-ignore
import video1 from '../../assets/singular.mp4';
import { useTranslation } from 'react-i18next';

export const Drones = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div className='tab-container'>
      <div className='tab-text'>
        <div className='tab-text singular-text'>
            <img src={singularIT} alt="" />
            <h3>{t('servicios.drones.title')}</h3>
            <p>{t('servicios.drones.text1')}
            <br /><br />
            <b>
            {t('servicios.drones.text2')}
            </b>
            <br /><br />
            {t('servicios.drones.text3')}
            </p>
        </div>
      </div>
      <div className='drone-video'>
        <video src={video1} autoPlay muted controls></video>
      </div>
      <div className='box-link-blue'>
          <a href="https://singularstories.cl/" target='_blank' className='link-blue'>IR A LA WEB</a>
        </div>
    </div>
  )
}
