import '../styles/header.scss';
import Hamburger from 'hamburger-react'
import { useState } from 'react';
//import logo from '../assets/logo-main.svg';
// @ts-ignore
import logo from '../assets/logo-msa.svg';
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const [t, i18n] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false)
  const setOpen = () => setIsOpen(!isOpen)

  const [language, setLanguage] = useState(true)
  const selectedLang = () => setLanguage(!language)

  const handleNavLinkClick = (id: any) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setIsOpen(false); // Cerrar el menú después de hacer clic en un enlace
    }
  };
  
  return (
    <div className='header'>
      <div className='brand'>
        <Link to='/' ><img src={logo} alt="" /></Link>
        <div className='lang-responsive show'>
            <button onClick={() => { i18n.changeLanguage("es"); selectedLang() }}className={language ? "lang-selected" : "none-selected"}>ES</button>
            <span> | </span>
            <button onClick={() => {i18n.changeLanguage("en"); selectedLang()}} className={!language ? "lang-selected" : "none-selected"}>EN</button>
          </div>
      </div>
      <div className='inner-header'>
      <div className='brand-responsive'>
        <Link to='/' ><img src={logo} alt="" /></Link>
      </div>
        <div className='hamburguer'>
          <Hamburger 
            toggled={isOpen} 
            toggle={setOpen} 
          />
        </div>
        <div className={`navbar ${isOpen ? "show-navbar" : ''}`}>
          <Link to='/' smooth className='link' onClick={() => setIsOpen(false)}>{t('header.inicio')}</Link>
          <Link to='/#impacto' smooth className='link' onClick={() => setIsOpen(false)}>{t('header.impact')}</Link>
          <Link to='/#servicios' smooth className='link' onClick={() => setIsOpen(false)}>{t('header.services')}</Link>
          <Link to='/somosMSA' smooth className='link' onClick={() => setIsOpen(false)}>{t('header.msa')}</Link>
          {/* <Link to='/quienes-somos' smooth className='link' onClick={() => setIsOpen(false)}>{t('header.about')}</Link> */}
          <Link to='/fundacion' smooth className='link' onClick={() => setIsOpen(false)}>{t('header.foundation')}</Link>
          <Link to='/#contacto' smooth className='link' onClick={() => setIsOpen(false)}>{t('header.contact')}</Link>
          <div className='lang-responsive hide'>
            <button onClick={() => { i18n.changeLanguage("es"); selectedLang() }}className={language ? "lang-selected" : "none-selected"}>ES</button>
            <span> | </span>
            <button onClick={() => {i18n.changeLanguage("en"); selectedLang()}} className={!language ? "lang-selected" : "none-selected"}>EN</button>
          </div>
        </div>
      </div>
    </div>
  )
}
