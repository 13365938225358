import React, { useEffect } from "react";

import { Header } from "../components/Header";
import "../styles/somosMsa.scss";
// @ts-ignore
import msa from "../assets/somosMSA.svg";
// @ts-ignore
import video1 from "../assets/IMPACTO EN LATAM.mp4";
import { Footer } from "../components/Footer/Footer";
import { useTranslation } from "react-i18next";

import "aos/dist/aos.css";
function ScrollAnimation() {
  useEffect(() => {
    const Aos = require("aos");
    Aos.init({ duration: 1000 });
  }, []);
  return null;
}
export const SomosMsa = () => {
  const [t, i18n] = useTranslation("global");
  window.scrollTo(0, 0);
  return (
    <>
      <ScrollAnimation />
      <div className="animation" data-aos="none">
        <Header />
      </div>
        <div className="animation" data-aos="fade" data-aos-duration="400">
      <div className="tu-componente">
          <div className="contenido">
            <div className="contenido-container">
              <img src={msa} alt="" />
              <p>
                {t("about.contextos")}
                <br />
                <br />
                {t("about.proposito")}
                <br />
                <br />
                {t("about.desafios_limites")}
              </p>
              <p>
                <br />
                <b>{t("about.manifiesto")}</b>
                <br />
              </p>
              <h4> {t("about.organizacion")}</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="animation" data-aos="fade" data-aos-duration="400">
        <div className="info-somos-msa">
          {/* <div className='somos-msa-video'>
          <video src={video1} autoPlay muted controls></video>
        </div> */}
          {/* <div className='valores'>
          <h2>VALORES</h2>
          <p>Estos son los valores específicos que se enmarcan dentro de nuestra premisa de 
          trabajar en equipo bajo los pilares de la determinación, convicción y pasión en 
          una organización cuya filosofía pone a las personas en  el centro para fomentar 
          el sentimiento de pertenencia.</p>
        </div> */}
          <div className="valores">
            <span>dream team</span>
            <h2>
              <a
                href="https://www.linkedin.com/company/grupo-msa/jobs/?viewAsMember=truehttps://www.linkedin.com/company/grupo-msa/jobs/?viewAsMember=true"
                target="_blank"
              >
                {t("about.title")}
              </a>
            </h2>
            <p>{t("about.parrafo")}</p>
          </div>
        </div>
      </div>
      <div className="animation" data-aos="fade" data-aos-duration="400">
        <Footer />
      </div>
    </>
  );
};
