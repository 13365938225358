import React, { useEffect } from "react";
import { Contacto } from "../components/Contacto/Contacto";
import CoverSlider from "../components/Cover/CoverSlider";
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header";
import { Impacto } from "../components/Impacto/Impacto";
import { Servicios } from "../components/Servicios/Servicios";
//import AOS from 'aos'
import "aos/dist/aos.css";

function ScrollAnimation() {
  useEffect(() => {
    const Aos = require("aos");
    Aos.init({ duration: 2000 });
  }, []);
  return null;
}

export const Home = () => {
  return (
    <>
      <ScrollAnimation />
      <div className="animation" data-aos="none">
        <Header />
      </div>
      <div className="animation" data-aos="fade" data-aos-delay="300">
        <CoverSlider />
      </div>
      <div className="animation" data-aos="fade">
        <Impacto />
      </div>
      <div className="animation" data-aos="fade">
        <Servicios />
      </div>
      <div className="animation" data-aos="fade" data-aos-once="true">
        <Contacto />
      </div>
      <div className="animation" data-aos="fade" data-aos-once="true">
        <Footer />
      </div>
    </>
  );
};
