import '../../styles/tecnologiaElectoral.scss';
// @ts-ignore
import singularIT from '../../assets/singularIT.svg';
import CarouselCard from '../Carrousel';
import { useTranslation } from 'react-i18next';


export const Software = () => {
  const [t, i18n] = useTranslation("global");
  const cardData = [
    {
    
        "title": "TICKETING"
      },
      {
   
        "title": "RETAIL RFID"
      },
      {
      
        "title": t('servicios.software.card1')
      },
      {
     
        "title": "IOT & SMART CITIES"
      }
]
  return (
    <div className='tab-container'>
      <div className='tab-text'>
      <div className='tab-text software-text'>
            <img src={singularIT} alt="" />
            <p dangerouslySetInnerHTML={{ __html: t('servicios.software.text1') } as any}/>
            <span>{t('servicios.software.text2')}</span>
        </div>
      </div>
      <CarouselCard cardConfig={cardData} />
      <div className='box-link-blue'>
          <a href="https://singularit.com.ar/" target='_blank' className='link-blue'>{t('servicios.software.button')}</a>
        </div>
    </div>
  )
}
