import "../../styles/videoCompany.scss";
// @ts-ignore
import video1 from '../../assets/IMPACTO EN LATAM.mp4';
import { useTranslation } from 'react-i18next';

export const VideoCompany = () => {
	const [t, i18n] = useTranslation("global");
  return (
		<div className="container">
			<div className="left-part">
			<h2 dangerouslySetInnerHTML={{ __html: t('impacto.video') } as any}/>
			</div>
			<div className="right-part">
				<video src={video1} controls autoPlay muted></video>
			</div>
	</div>
  )
}
