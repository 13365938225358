import React, { useEffect, useState } from "react";
import "../styles/noticias.scss";
import { Link } from "react-router-dom";
// @ts-ignore
import arrow from "../assets/arrow-back.png";

interface NewsItem {
  id: string;
  image_thumb: string;
  category: string;
  title: string;
  description_short: string;
}

export const Noticias: React.FC = () => {
  const [news, setNews] = useState<NewsItem[]>([]);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/noticias/news.json`)
      .then((response) => response.json())
      .then((data) => setNews(data))
      .catch((error) => console.error("Error al cargar el archivo JSON:", error));
  }, []);

  return (
    <div className="noticias">
      <div className="inner-noticias">
        <div className="noticias-header">
          <div className="next back">
            <a href="/">
              <img src={arrow} alt="" />
              volver
            </a>
          </div>
          <div className="next">Noticias</div>
        </div>
        <div className="grid-noticias">
          <div id="news-list" className="grid-noticias-container">
            {news.map((item) => (
              <Link to={`/nota/${item.id}`} key={item.id}>
                <div className="news-card">
                  <img src={`${process.env.PUBLIC_URL}/noticias/imagenes/${item.image_thumb}`} alt="" />
                  <span className="cat">{item.category}</span>
                  <h3>{item.title}</h3>
                  <p>{item.description_short}</p>
                  <h4>Leer nota</h4>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
