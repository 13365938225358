// @ts-ignore
import xCruza from '../../assets/xCruza.svg';
import CarouselCard from '../Carrousel';
import { useTranslation } from 'react-i18next';

export const Diseño = () => {
  const [t, i18n] = useTranslation("global");
  const cardData = [
    {
    
        "title": t('servicios.diseño.card1')
      },
      {
   
        "title": "BRANDING"
      },
      {
      
        "title": t('servicios.diseño.card2')
      },
      {
     
        "title": t('servicios.diseño.card3')
      }
]
  return (
    <div className='tab-container'>
    <div className='tab-text'>
      <div className='tab-text singular-text'>
          <img src={xCruza} alt="" />
          <p dangerouslySetInnerHTML={{ __html: t('servicios.diseño.texto1') } as any} />
      </div>
    </div>
    <CarouselCard cardConfig={cardData} />
    <div className='box-link-blue'>
        <a href="https://xcruza.com/en/home-en/" target='_blank' className='link-blue'>{t('servicios.software.button')}</a>
      </div>
  </div>
  )
}
