import { useState } from 'react';
import '../../styles/servicios.scss';
import { TecnologiaElectoral } from './TecnologiaElectoral';
import { Software } from './Software';
import { Drones } from './Drones';
import { Inteligencia } from './Inteligencia';
import { Diseño } from './Diseño';
import { useTranslation } from 'react-i18next';

export const Servicios = () => {
	const [t, i18n] = useTranslation("global");
		const [activeTab, setActiveTab] = useState(1);
	
		const handleTabClick = (tabNumber: number) => {
			setActiveTab(tabNumber);
		};

  return (
	<div className='servivios-component'>
		<div id='servicios'></div>
		<div className='flex-container column'>
        <div className='tite'>
			<span> {t('servicios.span')}</span>
        	<h2> {t('servicios.title')}</h2>
        </div> 
		<div className="tabs-container">
				<div className='tabs'>
				<div className={`tab ${activeTab === 1 && 'active'}`} onClick={() => handleTabClick(1)}>
        {t('servicios.tab1')}
      	</div>
      	<div className={`tab ${activeTab === 2 && 'active'}`} onClick={() => handleTabClick(2)}>
		  {t('servicios.tab2')}
      	</div>
      	<div className={`tab ${activeTab === 3 && 'active'}`} onClick={() => handleTabClick(3)}>
		  {t('servicios.tab3')}
      	</div>
      	<div className={`tab ${activeTab === 4 && 'active'}`} onClick={() => handleTabClick(4)}>
		  {t('servicios.tab4')}
      	</div>
      	<div className={`tab ${activeTab === 5 && 'active'}`} onClick={() => handleTabClick(5)}>
		  {t('servicios.tab5')}
      	</div>
			</div>
      <div className="tab-content">
        {activeTab === 1 && <TecnologiaElectoral />}
        {activeTab === 2 && <Software />}
        {activeTab === 3 && <Drones />}
        {activeTab === 4 && <Inteligencia />}
        {activeTab === 5 && <Diseño />}
      </div>
    </div>
    </div>
	</div>
  )
}
