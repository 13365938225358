import "../../styles/impacto.scss";
// @ts-ignore
import bg from "../../assets/newbg.jpg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

export const Impacto = () => {
  const [t, i18n] = useTranslation("global");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="impacto-contianer">
      <div id="impacto"></div>
      <div className="contenedor">
        <div className="text">
          <h2
            className={isMobile ? "mobile-title" : "desktop-title"}
            dangerouslySetInnerHTML={{
              __html: isMobile ? t("impacto.title-mb") : t("impacto.title")
            } as any}
          />
          <p>{t("impacto.text")}</p>
        </div>
        <div className="box-link-blue">
          <a href="noticias/articulo.html?id=40" className="link-blue">
            {t("impacto.button2")}
          </a>
        </div>
        <a href="/noticias" className="boton-link-noticias">
          {t("impacto.button1")}
        </a>
      </div>
    </div>
  );
};
