import '../../styles/footer.scss';
// @ts-ignore
import phone from '../../assets/icon-phone.svg';
// @ts-ignore
import email from '../../assets/icon-email.svg';
// @ts-ignore
import location from '../../assets/location.svg';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div className='footer-container'>
       <div className='flex-container'>
       <div className="container-footer">
      <div className="column-f title-column">
        <h2>Media</h2>
        <p>{t('footer.media.text1')}</p>
        <p>{t('footer.media.text2')}</p>
        <p>+54 9 11 4089-6667</p>
      </div>
      <div className="column-f">
        <p><i className="icon"><img src={phone} alt="" /></i>+54 11 6091-1540</p>
      </div>
      <div className="column-f">
        <p><i className="icon"><img src={email} alt="" /></i>{t('footer.email')}</p>
      </div>
      <div className="column-f">
        <p><i className="icon"><img src={location} alt="" /></i>{t('footer.direction')}</p>
      </div>
    </div>
       </div>
    </div>
  )
}
