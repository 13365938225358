import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/coverSlider.scss";
// @ts-ignore
import slider1 from "../../assets/slider1.jpg";
// @ts-ignore
import slider2 from "../../assets/slider2.jpg";
// @ts-ignore
import slider3 from "../../assets/slider3.jpg";
// @ts-ignore
import slider4 from "../../assets/slider4.jpg";
// @ts-ignore
import slider5 from "../../assets/slider5.jpg";
// @ts-ignore
import slider6 from "../../assets/slider6.jpg";
// @ts-ignore
import mobile1 from "../../assets/mobile-1.jpg";
// @ts-ignore
import logomsa from "../../assets/logo-msa.png";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

export const CoverSlider = () => {
  const [t, i18n] = useTranslation("global");
  const CustomPrevArrow = (props: any) => (
    <div onClick={props.onClick} className="slick-prev">
      Anterior
    </div>
  );

  const CustomNextArrow = (props: any) => (
    <div onClick={props.onClick} className="slick-next">
      Siguiente
    </div>
  );

  const isDesktop = useMediaQuery({ minWidth: 768 });

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    //autoplay: true,
    //autoplaySpeed: 3000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const slidesData = [
    {
      imageUrl: isDesktop ? slider1 : mobile1,
      text: t("cover.title1"),
      year: t("cover.subtitle"),
      data1: { number1: "+50", info1: "millones de votos" },
      data2: { number2: "+80", info2: "ciudades a lo largo del continente" },
      data3: { number3: "+30", info3: "años de innovación" },
    },
    {
      imageUrl: slider2,
    },
    {
      imageUrl: slider3,
    },
    {
      imageUrl: slider4,
    },
    {
      imageUrl: slider5,
    },
    {
      imageUrl: slider6,
    },
  ];

  return (
    <Slider {...settings}>
      {slidesData.map((slide, index) => (
        <div key={index}>
          <div
            className="slide"
            style={{ backgroundImage: `url(${slide.imageUrl})` }}
          >
            <div className="slide-content">
              <img src={logomsa} alt="Logo" />
              <h1>{slide.text}</h1>
              <p>{slide.year}</p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default CoverSlider;
