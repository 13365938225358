
// @ts-ignore
import { useTranslation } from 'react-i18next';
// @ts-ignore
import economatica from '../../assets/economatica.svg';
import CarouselCard from '../Carrousel';

export const Inteligencia = () => {
  const [t, i18n] = useTranslation("global");
  const cardData = [
    {
        "title": t('servicios.bursatil.card1')
      },
      {
   
        "title": t('servicios.bursatil.card2')
      },
      {
      
        "title": "Family offices"
      },
      {
     
        "title":  t('servicios.bursatil.card3')
      },
      {
     
        "title":  t('servicios.bursatil.card4')
      },
      {
      
        "title":  t('servicios.bursatil.card5')
      },
      {
     
        "title": "Private banks y wealth management"
      },
      {
     
        "title":  t('servicios.bursatil.card6')
      },
]

  return (
    <div className='tab-container'>
    <div className='tab-text'>
            <img src={economatica} alt="" />
            <p>{t('servicios.bursatil.texto1')}</p>
        </div>
        <div className='datos-container'>
            <div className='box-data'>
                <span>+45</span>
                <p>{t('servicios.bursatil.number1')}</p>
            </div>
            <div className='box-data'>
                <span>+250</span>
                <p>{t('servicios.bursatil.number2')}</p>
            </div>
            <div className='box-data'>
                <span>+5000</span>
                <p>{t('servicios.bursatil.number3')}</p>
            </div>
            <div className='box-data'>
                <span>+25.000</span>
                <p>{t('servicios.bursatil.number4')}</p>
            </div>
        </div>
        <CarouselCard cardConfig={cardData} />
        <div className='box-link-blue'>
          <a href="https://economatica.com/" target='_blank' className='link-blue'>{t('servicios.software.button')}</a>
        </div>
    </div>
  )
}
