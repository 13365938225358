import { Header } from "../components/Header";
import "../styles/fundacion.scss";
// @ts-ignore
import fundemos from "../assets/fundemos.svg";
// @ts-ignore
import icon1 from "../assets/icon-1.svg";
// @ts-ignore
import icon2 from "../assets/icon-8.svg";
// @ts-ignore
import icon3 from "../assets/icon-2.svg";
// @ts-ignore
import icon4 from "../assets/icon-6.svg";
// @ts-ignore
import icon5 from "../assets/icon-4.svg";
// @ts-ignore
import icon6 from "../assets/icon-7.svg";
// @ts-ignore
import icon7 from "../assets/icon-3.svg";
// @ts-ignore
import icon8 from "../assets/icon-10.svg";
// @ts-ignore
import icon9 from "../assets/icon-5.svg";
// @ts-ignore
import areas from "../assets/fundacion-areas.png";

import { Footer } from "../components/Footer/Footer";
import { useTranslation } from "react-i18next";

export const Fundacion = () => {
  const [t, i18n] = useTranslation("global");
  window.scrollTo(0, 0);

  const itemsData = [
    { id: 1, imageUrl: icon1, title: t("foundation-grid.item1") },
    { id: 2, imageUrl: icon2, title: t("foundation-grid.item2") },
    { id: 3, imageUrl: icon3, title: t("foundation-grid.item3") },
    { id: 4, imageUrl: icon4, title: t("foundation-grid.item4") },
    { id: 5, imageUrl: icon5, title: t("foundation-grid.item5") },
    { id: 6, imageUrl: icon6, title: t("foundation-grid.item6") },
    { id: 7, imageUrl: icon7, title: t("foundation-grid.item7") },
    { id: 8, imageUrl: icon8, title: t("foundation-grid.item8") },
    { id: 9, imageUrl: icon9, title: t("foundation-grid.item9") },
  ];

  return (
    <>
      <div className="animation" data-aos="none">
        <Header />
      </div>
      <div className="animation" data-aos="fade">
        <div className="fundacion">
          <div className="contenido">
            <div className="contenido-container">
              <img src={fundemos} alt="" />
              <p>
                {t("foundation.contextos")}
                <br />
                <br />
                {t("foundation.proposito")}
                <br />
                <br />
                {t("foundation.desafios_limites")}
              </p>
            </div>
            <div className="box-link-foundation">
              <a
                href="https://www.linkedin.com/feed/update/urn:li:activity:7153108893072535552"
                target="_blank"
                className="link-blue"
              >
                {t("foundation.boton")}
              </a>
            </div>
            <div className="nos-mueve">
              <div className="text">
                <h2>{t("foundation.title")}</h2>
                <p>{t("foundation.intro")}</p>
                <p>{t("foundation.parrafo")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="animation" data-aos="fade" data-aos-duration="400">
        <div className="container-foundation">
          <div className="grid-container-rows">
            {itemsData.map((item) => (
              <div key={item.id} className="grid-item-foundation">
                <img src={item.imageUrl} alt={`Imagen ${item.id}`} />
                <h3>{item.title}</h3>
              </div>
            ))}
          </div>
          <div className="areas-img">
            <img src={areas} alt="" />
          </div>
        </div>
      </div>
      <div className="animation" data-aos="fade" data-aos-duration="400">
        <Footer />
      </div>
    </>
  );
};
