import React, { useEffect, useState } from "react";
import "../styles/nota.scss"; // Importa el archivo de estilos hamburgers
import { useParams } from "react-router-dom";
// @ts-ignore
import arrow from "../assets/arrow-back.png";

interface Article {
  id: number;
  category: string;
  title: string;
  description_short: string;
  image: string;
  body: string;
}

interface NewsItem {
  id: string;
  image_thumb: string;
  image: string;
  category: string;
  title: string;
  description_short: string;
  body: string;
}

export function Nota() {
  const [article, setArticle] = useState<NewsItem | null>(null);

  const { idNota } = useParams<{ idNota: string }>();

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/noticias/news.json`)
      .then((response) => response.json())
      .then((data) => {
        setArticle(data.find((item: any) => item.id == idNota));
      })
      .catch((error) => console.error("Error al cargar el archivo JSON:", error));
  }, [idNota]);

  return (
    <div className="noticias" id="noticias">
      <div className="inner-noticias">
        <div className="notas-header">
          <div className="next back">
            <a href="/noticias">
              <img src={arrow} alt="" />
              volver
            </a>
          </div>
          <div className="next">Noticias</div>
        </div>
        {article && (
          <div className="articulo">
            <div className="inner-articulo">
              <span className="cat">{article.category}</span>
              <h3>{article.title}</h3>
              <p className="extracto">{article.description_short}</p>
              <img src={`${process.env.PUBLIC_URL}/noticias/imagenes/${article.image}`} alt="" />
              <p className="cuerpo">{article.body}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
