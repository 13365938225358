import { BrowserRouter as Router } from 'react-router-dom';
import { Main } from './components/Main';
import './styles/global.scss';

function App() {
  return (
    <Router>
      <Main />
    </Router>
  );
}

export default App;