import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/carrousel.scss';

type CarrouselProps = {
    cardConfig: any[];
  };

const Carousel = ({ cardConfig }: CarrouselProps) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: cardConfig.length > 4,
    prevArrow: <div className="slick-prev"></div>,
    nextArrow: <div className="slick-next"></div>,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          arrows: cardConfig.length > 4, 
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2, 
          arrows: cardConfig.length > 2, 
        },
      },
    ],
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {cardConfig.map((card: any, index: number) => (
          <div key={index} className="card">
            <div className="card-content">
              <span className="icon">
                <img src={card.icon} alt="" />
              </span>
              <p>{card.title}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
